import {
  Alignment,
  BackgroundColor,
  Block as BlockSDS,
} from '@snapchat/snap-design-system-marketing';
import type { FC, ReactNode } from 'react';

import { Content } from '../../../../components/Content';
import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { renderRichTextMarkingsOnly } from '../../../../utils/renderText/renderRichText';
import { RegistrationForm } from './RegistrationForm';
import type { RegistrationBlockData } from './RegistrationFormBlockQuery';
import { query } from './RegistrationFormBlockQuery';

export const RegistrationFormBlock: FC<{
  id: string;
  preChildren?: ReactNode;
  postChildren?: ReactNode;
}> = props => {
  const { data } = useContentfulQuery<RegistrationBlockData, ContentfulIdVariable>(query, {
    variables: { id: props.id },
  });

  if (!data?.registrationFormBlock) return null;

  const {
    eyebrow,
    title,
    subtitle,
    titleAlignmentDesktop = Alignment.Center,
    titleAlignmentMobile = Alignment.Start,
    backgroundColor = BackgroundColor.White,
    fullHeight,
    formRowsCollection = { items: [] },
    formAnalytics = {
      label: 'Submit',
      sys: { id: `form-analytics-${props.id}` },
    },
    connector,
    rememberRegistration,
    confirmationSlug: slug,
    unknownErrorText,
    sideContent,
  } = data.registrationFormBlock ?? {};

  return (
    <BlockSDS
      eyebrow={eyebrow}
      title={renderRichTextMarkingsOnly(title)}
      subtitle={renderRichTextMarkingsOnly(subtitle)}
      titleAlignment={titleAlignmentDesktop}
      titleAlignmentMobile={titleAlignmentMobile}
      backgroundColor={backgroundColor}
      fullHeight={fullHeight}
      preChildren={props.preChildren}
      postChildren={props.postChildren}
    >
      {sideContent && <Content {...sideContent} />}
      <RegistrationForm
        id={props.id}
        formAnalytics={formAnalytics}
        formRowsCollection={formRowsCollection}
        rememberRegistration={rememberRegistration === true}
        confirmationUrl={slug?.slug ?? ''}
        unknownErrorText={unknownErrorText!}
        connector={connector!}
      />
    </BlockSDS>
  );
};
